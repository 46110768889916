// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import site from '../views/Site/store';
import category from '../views/Master/Category/store';
import subCategory from '../views/Master/SubCategory/store';
import customerCategory from '../views/Master/CustomerCategory/store';
import designCategory from '../views/Master/DesignCategory/store';
import fieldCategory from '../views/Master/FieldCategory/store';
import customerBusinessDetail from '../views/Master/CustomerBusinessDetail/store';
import taxMaster from '../views/Master/TaxMaster/store';
import productMaster from '../views/ProductMaster/store';
import manageStock from '../views/ManageStock/store';
import manageCustomer from '../views/ManageCustomer/store';
import shippingRule from '../views/ShippingRules/store';
import locationSlice from '../views/Location/store';
import brand from '../views/Master/Brand/store';
import quantity from '../views/Master/Quantity/store';
import unit from '../views/Master/Unit/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    site,
    category,
    subCategory,
    customerCategory,
    designCategory,
    fieldCategory,
    customerBusinessDetail,
    taxMaster,
    productMaster,
    manageStock,
    shippingRule,
    locationSlice,
    brand,
    quantity,
    unit,
    manageCustomer
};

export default rootReducer;
