import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSubCategory = createAsyncThunk('subCategorySlice/getSubCategory', async () => {
    try {
        let response = await axios.get('/sub-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubCategoryOption = createAsyncThunk('subCategorySlice/getSubCategoryOption', async () => {
    try {
        let response = await axios.get('/sub-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSubCategoryById = createAsyncThunk('subCategorySlice/getSubCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/sub-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSubCategory = createAsyncThunk('subCategorySlice/createSubCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/sub-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category added successfully.');
        dispatch(getSubCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubCategory = createAsyncThunk('subCategorySlice/updateSubCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/sub-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category updated succesfully.');
        dispatch(getSubCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSubCategory = createAsyncThunk('subCategorySlice/deleteSubCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/sub-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category deleted successfully.');
        dispatch(getSubCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubCategoryStatus = createAsyncThunk('subCategorySlice/updateSubCategoryStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/sub-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSubCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const subCategorySlice = createSlice({
    name: 'subCategorySlice',
    initialState: {
        data: [],
        option: [],
        selectedSubCategory: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getSubCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSubCategoryOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getSubCategoryById.fulfilled, (state, action) => {
            state.selectedSubCategory = action.payload;
            return;
        });
    }
});

export default subCategorySlice.reducer;
