//** City Api

import { createAsyncThunk } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCity = createAsyncThunk('locationSliceData/getCity', async () => {
    try {
        let response = await axios.get('/City', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCity = createAsyncThunk('locationSliceData/createCity', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/City', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City added succesfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCity = createAsyncThunk('locationSliceData/updateCity', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/City/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City updated succesfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteCity = createAsyncThunk('locationSliceData/deleteCity', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/City/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City deleted successfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('locationSliceData/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/City/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
