import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCustomerBusinessDetail = createAsyncThunk('customerBusinessDetailSlice/getCustomerBusinessDetail', async () => {
    try {
        let response = await axios.get('/customer-business-detail', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerBusinessDetailById = createAsyncThunk('customerBusinessDetailSlice/getCustomerBusinessDetailById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/customer-business-detail/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomerBusinessDetail = createAsyncThunk(
    'customerBusinessDetailSlice/createCustomerBusinessDetail',
    async (data, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.post('/customer-business-detail', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Customer Business Detail added successfully.');
            dispatch(getCustomerBusinessDetail());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateCustomerBusinessDetail = createAsyncThunk(
    'customerBusinessDetailSlice/updateCustomerBusinessDetail',
    async (data, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/customer-business-detail/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Customer Business Detail updated succesfully.');
            dispatch(getCustomerBusinessDetail());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const deleteCustomerBusinessDetail = createAsyncThunk(
    'customerBusinessDetailSlice/deleteCustomerBusinessDetail',
    async (deleteId, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.delete('/customer-business-detail/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Customer Business Detail deleted successfully.');
            dispatch(getCustomerBusinessDetail());

            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateCustomerBusinessDetailStatus = createAsyncThunk(
    'customerBusinessDetailSlice/updateCustomerBusinessDetailStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/customer-business-detail/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getCustomerBusinessDetail());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const customerBusinessDetailSlice = createSlice({
    name: 'customerBusinessDetailSlice',
    initialState: {
        data: [],
        selectedCustomerBusinessDetail: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerBusinessDetail.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCustomerBusinessDetailById.fulfilled, (state, action) => {
            state.selectedCustomerBusinessDetail = action.payload;
            return;
        });
    }
});

export default customerBusinessDetailSlice.reducer;
