import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getBrand = createAsyncThunk('brandSlice/getBrand', async () => {
    try {
        let response = await axios.get('/brand', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBrandOption = createAsyncThunk('brandSlice/getBrandOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/brand?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBrandById = createAsyncThunk('brandSlice/getBrandById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/brand/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBrand = createAsyncThunk('brandSlice/createBrand', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/brand', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Brand added successfully.');
        dispatch(getBrand());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBrand = createAsyncThunk('brandSlice/updateBrand', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/brand/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Brand updated succesfully.');
        dispatch(getBrand());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBrand = createAsyncThunk('brandSlice/deleteBrand', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/brand/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Brand deleted successfully.');
        dispatch(getBrand());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBrandStatus = createAsyncThunk('brandSlice/updateBrandStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/brand/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBrand());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const brandSlice = createSlice({
    name: 'brandSlice',
    initialState: {
        data: [],
        option: [],
        selectedBrand: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getBrand.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBrandOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getBrandById.fulfilled, (state, action) => {
            state.selectedBrand = action.payload;
            return;
        });
    }
});

export default brandSlice.reducer;
