import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getTaxMaster = createAsyncThunk('taxMasterSlice/getTaxMaster', async () => {
    try {
        let response = await axios.get('/tax-master', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTaxMasterOption = createAsyncThunk('taxMasterSlice/getTaxMasterOption', async () => {
    try {
        let response = await axios.get('/tax-master?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTaxMasterById = createAsyncThunk('taxMasterSlice/getTaxMasterById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/tax-master/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTaxMaster = createAsyncThunk('taxMasterSlice/createTaxMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/tax-master', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax Master added successfully.');
        dispatch(getTaxMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTaxMaster = createAsyncThunk('taxMasterSlice/updateTaxMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/tax-master/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax Master updated succesfully.');
        dispatch(getTaxMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTaxMaster = createAsyncThunk('taxMasterSlice/deleteTaxMaster', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/tax-master/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax Master deleted successfully.');
        dispatch(getTaxMaster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTaxMasterStatus = createAsyncThunk('taxMasterSlice/updateTaxMasterStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/tax-master/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getTaxMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const taxMasterSlice = createSlice({
    name: 'taxMasterSlice',
    initialState: {
        data: [],
        option: [],
        selectedTaxMasterData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getTaxMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTaxMasterOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getTaxMasterById.fulfilled, (state, action) => {
            state.selectedTaxMasterData = action.payload;
            return;
        });
    }
});

export default taxMasterSlice.reducer;
