import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getQuantity = createAsyncThunk('quantitySlice/getQuantity', async () => {
    try {
        let response = await axios.get('/quantity', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getQuantityOption = createAsyncThunk('quantitySlice/getQuantityOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/quantity?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getQuantityById = createAsyncThunk('quantitySlice/getQuantityById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/quantity/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createQuantity = createAsyncThunk('quantitySlice/createQuantity', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/quantity', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Quantity added successfully.');
        dispatch(getQuantity());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateQuantity = createAsyncThunk('quantitySlice/updateQuantity', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/quantity/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Quantity updated succesfully.');
        dispatch(getQuantity());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteQuantity = createAsyncThunk('quantitySlice/deleteQuantity', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/quantity/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Quantity deleted successfully.');
        dispatch(getQuantity());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateQuantityStatus = createAsyncThunk('quantitySlice/updateQuantityStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/quantity/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getQuantity());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const quantitySlice = createSlice({
    name: 'quantitySlice',
    initialState: {
        data: [],
        option: [],
        selectedQuantity: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getQuantity.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getQuantityOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getQuantityById.fulfilled, (state, action) => {
            state.selectedQuantity = action.payload;
            return;
        });
    }
});

export default quantitySlice.reducer;
