import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getStock = createAsyncThunk('manageStockSlice/getStock', async () => {
    try {
        let response = await axios.get('/stock', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createStock = createAsyncThunk('manageStockSlice/createStock', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/stock', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock added successfully.');
        dispatch(getStock());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStockById = createAsyncThunk('manageStockSlice/getStockById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/stock/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStock = createAsyncThunk('manageStockSlice/updateStock', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/stock/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock updated succesfully.');
        dispatch(getStock());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteStock = createAsyncThunk('manageStockSlice/deleteStock', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/stock/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock deleted successfully.');
        dispatch(getStock());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStockStatus = createAsyncThunk('manageStockSlice/updateStockStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/stock/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getStock());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const manageStockSlice = createSlice({
    name: 'manageStockSlice',
    initialState: {
        data: [],
        selectedStock: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getStock.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStockById.fulfilled, (state, action) => {
            state.selectedStock = action.payload;
            return;
        });
    }
});

export default manageStockSlice.reducer;
