import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCustomer = createAsyncThunk('customerSlice/getCustomer', async () => {
    try {
        let response = await axios.get('/client/user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerById = createAsyncThunk('customerSlice/getCustomerById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/client/user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomer = createAsyncThunk('customerSlice/createCustomer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/user', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer added successfully.');
        dispatch(getCustomer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomer = createAsyncThunk('customerSlice/updateCustomer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/user/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer updated succesfully.');
        dispatch(getCustomer());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCustomer = createAsyncThunk('customerSlice/deleteCustomer', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer deleted successfully.');
        dispatch(getCustomer());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerStatus = createAsyncThunk('customerSlice/updateCustomerStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/user/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getCustomer());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerApprovalStatus = createAsyncThunk('customerSlice/updateCustomerApprovalStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/user/approvalStatus/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Approval Status updated successfully.');
        dispatch(getCustomer());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProfilePictures = createAsyncThunk('customerSlice/removeProfilePicture', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/client/profile/remove-image/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer updated succesfully.');
        await dispatch(getCustomerById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: {
        data: [],
        selectedCustomer: {}
    },
    reducers: {
        removeCustomer: (state) => {
            state.selectedCustomer = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCustomerById.fulfilled, (state, action) => {
            state.selectedCustomer = action.payload;
            return;
        });
    }
});

export const { removeCustomer } = customerSlice.actions;
export default customerSlice.reducer;
