var service = {};

if (process.env.REACT_APP_NODE_ENV === 'production') {
    service.API_URL = 'https://dev-api.shreejitraders.org';
} else {
    service.API_URL = 'http://192.168.29.101:5003';
}

// service.API_URL = 'http://192.168.29.101:5003';

export default service;
