// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import { getCity } from './CityArea/store/index';
import { getState } from './State/store/index';
import { getDistrict } from './District/store/index';
import { getCountries } from './Country/store/index';

//** Location Cascade Api
export const getCountryOption = createAsyncThunk('locationSliceData/getCountryOption', async () => {
    try {
        let response = await axios.get('/country?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStateOption = createAsyncThunk('locationSliceData/getStateOption', async (id) => {
    try {
        let response = await axios.get('state/cascade/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDistrictOption = createAsyncThunk('locationSliceData/getDistrictOption', async (id) => {
    try {
        let response = await axios.get('district/cascade/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getCityOption = createAsyncThunk('locationSliceData/getCityOption', async (id) => {
    try {
        let response = await axios.get('city/cascade/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const locationSlice = createSlice({
    name: 'locationSliceData',
    initialState: {
        city: [],
        cityOption: [],
        country: [],
        countryOption: [],
        states: [],
        statesOption: [],
        district: [],
        districtOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getCountryOption.fulfilled, (state, action) => {
            state.countryOption = action?.payload;
            return;
        });
        builder.addCase(getStateOption.fulfilled, (state, action) => {
            state.statesOption = action?.payload;
            return;
        });

        builder.addCase(getDistrictOption.fulfilled, (state, action) => {
            state.districtOption = action?.payload;
            return;
        });
        builder.addCase(getCityOption.fulfilled, (state, action) => {
            state.cityOption = action?.payload;
            return;
        });

        builder.addCase(getCountries.fulfilled, (state, action) => {
            state.country = action?.payload;
            return;
        });

        builder.addCase(getState.fulfilled, (state, action) => {
            state.states = action?.payload;
            return;
        });

        builder.addCase(getDistrict.fulfilled, (state, action) => {
            state.district = action?.payload;
            return;
        });

        builder.addCase(getCity.fulfilled, (state, action) => {
            state.city = action?.payload;
            return;
        });
    }
});

export * from './State/store/index';
export * from './District/store/index';
export * from './CityArea/store/index';
export * from './Country/store/index';

export default locationSlice.reducer;
