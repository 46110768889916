//** District Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDistrict = createAsyncThunk('locationSliceData/getDistrict', async () => {
    try {
        let response = await axios.get('/District', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDistrict = createAsyncThunk('locationSliceData/createDistrict', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/District', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'District added succesfully.');
        await dispatch(getDistrict());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDistrict = createAsyncThunk('locationSliceData/updateDistrict', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/District/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'District updated succesfully.');
        await dispatch(getDistrict());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteDistrict = createAsyncThunk('locationSliceData/deleteDistrict', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/District/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'District deleted successfully.');
        await dispatch(getDistrict());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatusDistrict = createAsyncThunk('locationSliceData/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/District/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || ' Status updated successfully !');
        await dispatch(getDistrict());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
