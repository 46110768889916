import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getUnit = createAsyncThunk('unitSlice/getUnit', async () => {
    try {
        let response = await axios.get('/unit', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUnitOption = createAsyncThunk('unitSlice/getUnitOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/unit?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUnitById = createAsyncThunk('unitSlice/getUnitById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/unit/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUnit = createAsyncThunk('unitSlice/createUnit', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/unit', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Unit added successfully.');
        dispatch(getUnit());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateUnit = createAsyncThunk('unitSlice/updateUnit', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/unit/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Unit updated succesfully.');
        dispatch(getUnit());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteUnit = createAsyncThunk('unitSlice/deleteUnit', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/unit/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Unit deleted successfully.');
        dispatch(getUnit());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateUnitStatus = createAsyncThunk('unitSlice/updateUnitStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/unit/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getUnit());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const unitSlice = createSlice({
    name: 'unitSlice',
    initialState: {
        data: [],
        option: [],
        selectedUnit: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getUnit.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getUnitOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getUnitById.fulfilled, (state, action) => {
            state.selectedUnit = action.payload;
            return;
        });
    }
});

export default unitSlice.reducer;
