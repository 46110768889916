import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDesignCategory = createAsyncThunk('designCategorySlice/getDesignCategory', async () => {
    try {
        let response = await axios.get('/design-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDesignCategoryById = createAsyncThunk('designCategorySlice/getDesignCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/design-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDesignCategory = createAsyncThunk('designCategorySlice/createDesignCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/design-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Design Category added successfully.');
        dispatch(getDesignCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDesignCategory = createAsyncThunk('designCategorySlice/updateDesignCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/design-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Design Category updated succesfully.');
        dispatch(getDesignCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDesignCategory = createAsyncThunk('designCategorySlice/deleteDesignCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/design-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Design Category deleted successfully.');
        dispatch(getDesignCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDesignCategoryStatus = createAsyncThunk('designCategorySlice/updateDesignCategoryStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/design-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getDesignCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const designCategorySlice = createSlice({
    name: 'designCategorySlice',
    initialState: {
        data: [],
        selectedDesignCategory: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getDesignCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getDesignCategoryById.fulfilled, (state, action) => {
            state.selectedDesignCategory = action.payload;
            return;
        });
    }
});

export default designCategorySlice.reducer;
