import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getFieldCategory = createAsyncThunk('fieldCategorySlice/getFieldCategory', async () => {
    try {
        let response = await axios.get('/field-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFieldCategoryOption = createAsyncThunk('fieldCategorySlice/getFieldCategoryOption', async () => {
    try {
        let response = await axios.get('/field-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getFieldCategoryById = createAsyncThunk('fieldCategorySlice/getFieldCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/field-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFieldCategory = createAsyncThunk('fieldCategorySlice/createFieldCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/field-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Field Category added successfully.');
        dispatch(getFieldCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFieldCategory = createAsyncThunk('fieldCategorySlice/updateFieldCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/field-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Field Category updated succesfully.');
        dispatch(getFieldCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteFieldCategory = createAsyncThunk('fieldCategorySlice/deleteFieldCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/field-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Field Category deleted successfully.');
        dispatch(getFieldCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFieldCategoryStatus = createAsyncThunk('fieldCategorySlice/updateFieldCategoryStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/field-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getFieldCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const fieldCategorySlice = createSlice({
    name: 'fieldCategorySlice',
    initialState: {
        data: [],
        option: [],
        selectedFieldCategory: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getFieldCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getFieldCategoryOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getFieldCategoryById.fulfilled, (state, action) => {
            state.selectedFieldCategory = action.payload;
            return;
        });
    }
});

export default fieldCategorySlice.reducer;
